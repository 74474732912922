import BandwagonDotCom from "../../components/BandwagonDotCom"
import React from "react"
import TeamMember from "../../components/TeamMember"

import { StaticImage } from "gatsby-plugin-image"

const SethRussell = () => <BandwagonDotCom>
  <TeamMember
        headshot=<StaticImage src="../../images/seth-russell-headshot.png"
                alt="Seth Russell" />
        name="Seth Russell"
        position="Software Engineer"
  >
      <p>
        Seth is a 2017 graduate of New College of Florida, where all are deemed
        honors students and are required to do an undergrad thesis to graduate.
        Seth's thesis was on creating a game while writing a genre study of
        Role-Playing Games. While a student at New College, Seth was also a
        volunteer for the Bernie Sanders campaign, the New College Young
        Democrats, and the Sarasota Democratic Party, where his efforts earned
        him the designation as the 'voter registration person' on campus. His
        political participation continued after college, where he volunteered
        for the Bernie Sanders campaign again during the 2020 election cycle.
        He also worked as a Voting Systems Technician in Broward County,
        Florida amid the coronavirus pandemic. Post-primaries, he began
        contributing to the progressive open-source project Parsons, which
        allows progressive organizers to gather and transfer data between the
        REST API's of various political tools. The bulk of his work experience
        is in chatbot development with a few months in machine learning, which
        included data analysis and coding a machine learning solution using the
        Anaconda distribution of Python. This expertise will benefit Bandwagon
        with real-time assessment for identity infrastructure and future
        predictions and analysis.
      </p>
      <h3>Fun Fact</h3>
      <hr/>
      <p>
        Seth knows how to loom knit and crochet. He can make his own hats and
        fingerless gloves!
      </p>
      <h3>Why I love Bandwagon</h3>
      <hr/>
      <p>
        I'm just so happy to be able to put my skills to use and that my work
        has an impact.
      </p>
  </TeamMember>
</BandwagonDotCom>;

export default SethRussell
